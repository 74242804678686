import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/general/header'
import HomeMainContainer from '../components/home/home-main-container'
import HomeHero from '../components/home/home-hero'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Susanna</title>
        <meta property="og:title" content="Susanna" />
      </Helmet>
      <Header theme="home"></Header>
      <HomeMainContainer></HomeMainContainer>
      <HomeHero></HomeHero>
    </div>
  )
}

export default Home
