import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './loginButton.css';
import { SERVER_URL } from '../../config';
import { accessLevels } from './accessLevels';

const LoginButton = () => {
  const [userName, setUserName] = useState(null);
  const [userAccessLevel, setUserAccessLevel] = useState(0);

  useEffect(() => {
    console.log(`Making a request to ${SERVER_URL}/auth/user`); // Check URL
    
    axios.get(`${SERVER_URL}/auth/user`, { withCredentials: true })
      .then(response => {
        console.log('Response received:', response); // Check entire response

        if (response.data) {
          console.log('Response data:', response.data); // Check the response data

          setUserName(response.data.name);
          setUserAccessLevel(response.data.access_level);
        }
      })
      .catch(error => {
        console.error('Axios error:', error); // Check for any errors in the request
      });
  }, []);

  const handleLogin = () => {
    console.log('Login button clicked'); // Check when the login button is clicked
    window.location.href = `${SERVER_URL}/auth/login`;
  };

  if (userName) {
    const givenName = userName.split(' ')[0];
    return <div className={`login-button ${accessLevels[userAccessLevel]}`}>Hi {givenName}</div>;
  } else {
    return <button className={`login-button ${accessLevels[userAccessLevel]}`} onClick={handleLogin}>Login</button>;
  }
};

export default LoginButton;
