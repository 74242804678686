import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import './purch-menu-container.css';

const SidebarMenu = ({ menuItems }) => (
  <div className="sidebar">
    <nav className="nav">
      {menuItems.map((item, index) => (
        item.isLink ? 
          <Link key={index} to={item.link} className="navlink">
            {item.text}
          </Link>
        :
          <span key={index} className="text">
            {item.text}
          </span>
      ))}
    </nav>
    <Player
      src="https://lottie.host/ca636dc5-2cf9-4d89-ad13-cac650250c0c/LxnyXfZB26.json"
      speed="1"
      autoplay
      background="transparent"
      className="lottie-node"
    ></Player>
  </div>
);

SidebarMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string,
    isLink: PropTypes.bool,
  })).isRequired,
};

export default SidebarMenu;
