import React from 'react'

import PropTypes from 'prop-types'

import './p-hero.css'

const PFHero = (props) => {
  return (
    <div className="p-hero-hero">
      <div className="p-hero-about-container">
        <h1 className="p-hero-text">
          <span className="p-hero-text1">How to use Forecasting</span>
          <br></br>
        </h1>
        <span className="p-hero-text3">
          <br></br>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non
            volutpat turpis.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            Mauris luctus rutrum mi ut rhoncus. Integer in dignissim tortor.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
        <button className="p-hero-button button">{props.button}</button>
      </div>
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="p-hero-image"
      />
    </div>
  )
}

PFHero.defaultProps = {
  image_src:
    'https://images.unsplash.com/photo-1471086569966-db3eebc25a59?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIwfHxtaW5pbWFsaXNtJTIwcGxhbnR8ZW58MHx8fHwxNjI2MTgyODMw&ixlib=rb-1.2.1&w=1200',
  image_alt: 'image',
  button: 'Get Started',
}

PFHero.propTypes = {
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  button: PropTypes.string,
}

export default PFHero
