import React from 'react'
import { Link } from 'react-router-dom'
import { Player } from '@lottiefiles/react-lottie-player'
import PropTypes from 'prop-types'
import './p-menu-container.css'

const PFMenuContainer = ({ menuItems }) => {
  return (
    <div className="p-menu-container-menu-container">
      <div className="p-menu-container-sidebar">
        <nav className="p-menu-container-nav">
          {menuItems.map((item, index) => item.isLink ? (
            <Link to={item.link} className="p-menu-container-navlink" key={index}>
              {item.text}
              <br />
            </Link>
          ) : (
            <span className="p-menu-container-text" key={index}>
              {item.text}
              <br />
            </span>
          ))}
        </nav>
        <Player
          src="https://lottie.host/ca636dc5-2cf9-4d89-ad13-cac650250c0c/LxnyXfZB26.json"
          speed="1"
          autoplay
          background="transparent"
          className="p-menu-container-lottie-node"
        ></Player>
      </div>
    </div>
  )
}

PFMenuContainer.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string,
    isLink: PropTypes.bool,
  })).isRequired,
}

export default PFMenuContainer
