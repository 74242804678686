import React from 'react';

const NotAuthorized = () => (
  <div>
    <h1>Access Denied</h1>
    <p>You do not have permission to access this page. Please log in with an authorized account.</p>
  </div>
);

export default NotAuthorized;
