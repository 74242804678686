import React from 'react'

import PropTypes from 'prop-types'

import './home-hero.css'

const HomeHero = (props) => {
  return (
    <div className="home-hero-hero">
      <div className="home-hero-about-susanna">
        <h1 className="home-hero-t-about-susanna">{props.t_AboutSusanna}</h1>
        <span className="home-hero-text">
          <br></br>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non
            volutpat turpis.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            Mauris luctus rutrum mi ut rhoncus. Integer in dignissim tortor.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
        <button className="home-hero-button button">{props.button}</button>
      </div>
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="home-hero-image"
      />
    </div>
  )
}

HomeHero.defaultProps = {
  image_alt: 'image',
  t_AboutSusanna: 'Who is Susanna?',
  button: 'Get Started',
  image_src:
    'https://images.unsplash.com/photo-1471086569966-db3eebc25a59?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIwfHxtaW5pbWFsaXNtJTIwcGxhbnR8ZW58MHx8fHwxNjI2MTgyODMw&ixlib=rb-1.2.1&w=1200',
}

HomeHero.propTypes = {
  image_alt: PropTypes.string,
  t_AboutSusanna: PropTypes.string,
  button: PropTypes.string,
  image_src: PropTypes.string,
}

export default HomeHero
