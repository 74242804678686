import React from 'react'
import PropTypes from 'prop-types'
import './p-output-container.css'

const PAOutputContainer = (props) => {
  console.log("PAOutputContainer props:", props);
  return (
    <div className={`p-output-container-output-container ${props.rootClassName} `}>
      <h2 className="p-output-container-text">Results:</h2>
      {props.imageURL ? 
        <img src={props.imageURL} alt="Output" className="output-image" /> : 
        <div className="p-output-container-textinput input">
          {props.receivedText}
        </div>}
      <button onClick={props.onClear} className="clear-button">Clear</button>
    </div>
  )
}

PAOutputContainer.defaultProps = {
  rootClassName: '',
  receivedText: '',
  onClear: () => {},
  imageURL: '',
}

PAOutputContainer.propTypes = {
  rootClassName: PropTypes.string,
  receivedText: PropTypes.string,
  onClear: PropTypes.func,
  imageURL: PropTypes.string,
}

export default PAOutputContainer
