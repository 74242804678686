import React from 'react'

import PropTypes from 'prop-types'

import NavigationLinks1 from './navigation-links1'
import LoginButton from './loginButton'
import './header.css'

const Header = (props) => {
  return (
    <header data-role="Header" className="header-header">
      <img alt={props.Logo_alt} src={props.Logo_src} className="header-logo" />
      <div className="header-header-navigation-links">
        <NavigationLinks1 rootClassName="rootClassName10"></NavigationLinks1>
      </div>
      <LoginButton />
    </header>
  )
}


Header.defaultProps = {
  Logo_src: 'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  Logo_alt: 'logo',
  Login: 'Login',
}

Header.propTypes = {
  Logo_src: PropTypes.string,
  Logo_alt: PropTypes.string,
  Login: PropTypes.string,
}

export default Header
