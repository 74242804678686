import React from 'react';

const SettingsModal = ({ showSettings, toggleSettings, handleRuleChange, tempStopOrderRule, setTempStopOrderRule, tempOrderTriggerRule, setTempOrderTriggerRule, applyAndCloseSettings }) => {
    return (
        showSettings && (
            <div className="p-user-upload-settings-overlay">
                <div className="p-user-upload-settings-modal">
                    <button className="p-user-upload-settings-close" onClick={toggleSettings}></button>

                    <h2>Settings</h2>
                    <p>Modify your settings here. You can adjust the parameters for stop order and order trigger rules.</p>

                    <h3>Stop Order Rule</h3>
                    <div className="input-row">
                        <input
                            className="small-input"
                            type="number"
                            value={tempStopOrderRule.min}
                            onChange={handleRuleChange(setTempStopOrderRule, 'min')}
                            placeholder="Min"
                        />
                        <input
                            className="small-input"
                            type="number"
                            value={tempStopOrderRule.max}
                            onChange={handleRuleChange(setTempStopOrderRule, 'max')}
                            placeholder="Max"
                        />
                        <select
                            className="favor-dropdown"
                            value={tempStopOrderRule.favor}
                            onChange={handleRuleChange(setTempStopOrderRule, 'favor')}
                        >
                            <option value="high">High</option>
                            <option value="low">Low</option>
                        </select>
                    </div>

                    <h3>Order Trigger Rule</h3>
                    <div className="input-row">
                        <input
                            className="small-input"
                            type="number"
                            value={tempOrderTriggerRule.min}
                            onChange={handleRuleChange(setTempOrderTriggerRule, 'min')}
                            placeholder="Min"
                        />
                        <input
                            className="small-input"
                            type="number"
                            value={tempOrderTriggerRule.max}
                            onChange={handleRuleChange(setTempOrderTriggerRule, 'max')}
                            placeholder="Max"
                        />
                        <select
                            className="favor-dropdown"
                            value={tempOrderTriggerRule.favor}
                            onChange={handleRuleChange(setTempOrderTriggerRule, 'favor')}
                        >
                            <option value="high">High</option>
                            <option value="low">Low</option>
                        </select>
                    </div>

                    <button className="p-user-upload-settings-apply" onClick={applyAndCloseSettings}>Apply</button>
                </div>
            </div>
        )
    );
}

export default SettingsModal;
