import React from 'react'
import { Link } from 'react-router-dom'

import { Player } from '@lottiefiles/react-lottie-player'
import PropTypes from 'prop-types'

import './home-main-container.css'

const HomeMainContainer = (props) => {
  return (
    <div className="home-main-container-main-container">
      <div className="home-main-container-int-container">
        <div className="home-main-container-side-container">
          <div className="home-main-container-sidebar">
            <nav className="home-main-container-nav">
              <span className="home-main-container-text">{props.text}</span>
              <Link to="/purchasing" className="home-main-container-navlink">
                {props.text1}
              </Link>
              <span className="home-main-container-text1">{props.text2}</span>
              <span className="home-main-container-text2">{props.text3}</span>
              <span className="home-main-container-text3">
                <span>Contact</span>
                <br></br>
              </span>
            </nav>
            <Player
              src="https://lottie.host/d219a01c-90e7-48c2-a5de-dca569e2167e/xFFbTQkv3Y.json"
              speed="1"
              autoplay
              background="transparent"
              className="home-main-container-lottie"
            ></Player>
          </div>
        </div>
        <div className="home-main-container-center-container">
          <div className="home-main-container-above-title"></div>
          <h1>
            <span className="home-main-container-text6">Susanna AI</span>
            <br></br>
          </h1>
        </div>
          <div className="home-main-container-image-container"></div>
      </div>
    </div>
  )
}

HomeMainContainer.defaultProps = {
  text: 'About',
  text3: 'Management',
  text1: 'Purchasing',
  text2: 'Graphics',
}

HomeMainContainer.propTypes = {
  text: PropTypes.string,
  text3: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
}

export default HomeMainContainer