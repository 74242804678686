import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ProtectedRoute from './components/HOCs/ProtectedRoute';

import './style.css';
import Home from './views/home';
import Purchasing from './views/purchasing';
import PForecasting from './views/purch-forecasting';

import PAnalysis from './views/purch-analysis';

import NotAuthorized from './views/not-authorized';

import axios from 'axios';
import { CLIENT_ID, SERVER_URL } from './config';  // Ensure correct path

// Set default configuration
axios.defaults.withCredentials = true;

const App = () => {
  const [userAccessLevel, setUserAccessLevel] = useState(0);

  useEffect(() => {
    axios.get(`${SERVER_URL}/auth/user`, { withCredentials: true })
      .then(response => {
        setUserAccessLevel(response.data.access_level);
      })
      .catch(error => {
        // The user is likely not logged in, handle the error here
        console.log('Unable to fetch user information:', error);
        setUserAccessLevel(0);  // you may set default access level to 0 for non-logged in users
      });
  }, []);
  

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <Router>
        <Switch>
          <Route component={Home} exact path="/" />
          <Route component={NotAuthorized} exact path="/not-authorized" />
          <ProtectedRoute component={Purchasing} path="/purchasing" exact accessLevel={userAccessLevel} requiredLevel={2} />
          <ProtectedRoute component={PForecasting} path="/purchasing/purch-forecasting" exact accessLevel={userAccessLevel} requiredLevel={2} /> 
          <ProtectedRoute component={PAnalysis} path="/purchasing/purch-analysis" exact accessLevel={userAccessLevel} requiredLevel={2} />
        </Switch>
      </Router>
    </GoogleOAuthProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('app'));
