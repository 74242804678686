import React, { useMemo, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import Papa from 'papaparse';
import './p-data-viewer.css';

const DataViewer = ({ dataframe: csvString, className, stop_order_rule, order_trigger_rule}) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedCells, setSelectedCells] = useState({});

  useEffect(() => {
    if (csvString) {
      Papa.parse(csvString, {
        header: true,
        complete: (result) => {
          setData(result.data);
  
          // Define the columns for React Table
          const cols = result.meta.fields.map((key) => ({ // Ensure we're getting the header titles here
            Header: key,
            accessor: key,
          }));
          setColumns(cols);
        }
      });
    } else {
      setData([]);
      setColumns([]);
    }
  }, [csvString]);

  const getCellColor = (value, min, max, exc, favor) => {
    // If the value is negative or greater than the exception, set the color to gray
    if (value < 0 || value > exc) {
      const backgroundColor = 'gray';
      const textColor = 'black';
      return [backgroundColor, textColor];
    }
  
    // Define hues for Google red and Google blue
    const HUE_RED = 3;
    const HUE_BLUE = 220;
    let hue;
  
    if (favor === "low") {
      if (value <= min) {
        hue = HUE_RED;
      } else if (value >= max) {
        hue = HUE_BLUE;
      } else {
        // Calculate the ratio of the value's position within the range
        const ratio = (value - min) / (max - min);
        // Interpolate between the hues for Google red and Google blue
        hue = HUE_RED + ratio * (HUE_BLUE - HUE_RED);
      }
    } else if (favor === "high") {
      if (value <= min) {
        hue = HUE_BLUE;
      } else if (value >= max) {
        hue = HUE_RED;
      } else {
        // Calculate the ratio of the value's position within the range
        const ratio = (value - min) / (max - min);
        // Interpolate between the hues for Google blue and Google red
        hue = HUE_BLUE + ratio * (HUE_RED - HUE_BLUE);
      }
    } else {
      throw new Error("Invalid value for favor. Expected 'low' or 'high'.");
    }
  
    // Lower the saturation and lightness for a more pastel appearance
    const backgroundColor = `hsl(${hue}, 85%, 65%)`;
    const textColor = 'black';
  
    return [backgroundColor, textColor];
  };

  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const handleCellClick = (rowIndex, colIndex) => {
    const key = `${rowIndex}-${colIndex}`;
    setSelectedCells(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  if (!Array.isArray(data) || data.length === 0) {
    return (
      <div className={`${className} p-data-viewer-container`}>
        <div className="p-data-not-available">
          No data available
        </div>
      </div>
    );
  }

  return (
    <div {...getTableProps()} className={`${className} p-data-viewer-box`}>
      <table className="p-data-viewer-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, colIndex) => (
                <th key={colIndex} style={{ backgroundColor: '#b2b2b2'  }}>{column.render('Header')}</th>

              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, colIndex) => {
                  const cellValue = cell.value;
                  const isStopOrderColumn = cell.column.id === 'stop_order';
                  const isOrderTriggerColumn = cell.column.id === 'order_trigger';

                  const [stop_order_backgroundColor, stop_order_textColor] = isStopOrderColumn
                    ? getCellColor(cellValue, stop_order_rule.min, stop_order_rule.max, stop_order_rule.exc, stop_order_rule.favor)
                    : ['', ''];

                  const [order_trigger_backgroundColor, order_trigger_textColor] = isOrderTriggerColumn
                    ? getCellColor(cellValue, order_trigger_rule.min, order_trigger_rule.max, order_trigger_rule.exc, order_trigger_rule.favor)
                    : ['', ''];

                  // Merge styles
                  const backgroundColor = isStopOrderColumn ? stop_order_backgroundColor : (isOrderTriggerColumn ? order_trigger_backgroundColor : '');
                  const textColor = isStopOrderColumn ? stop_order_textColor : (isOrderTriggerColumn ? order_trigger_textColor : '');
                  const cellClass = colIndex === 0 ? 'sticky-column' : '';

                  return (
                    <td
                      {...cell.getCellProps()}
                      onClick={() => handleCellClick(rowIndex, colIndex)}
                      className={`${cellClass} ${selectedCells[`${rowIndex}-${colIndex}`] ? 'p-data-viewer-table-cell-highlighted' : ''}`}
                      style={{ backgroundColor, color: textColor }} // existing styles
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}

              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataViewer;
