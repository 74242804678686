import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/general/header'
import PFMainContainer from '../components/purchaseForecasting/p-main-container'
import PFHero from '../components/purchaseForecasting/p-hero'
import './purch-forecasting.css'

const PForecasting = (props) => {
  return (
    <div className="purch-forecasting-container">
      <Helmet>
        <title>PForecasting - Susanna</title>
        <meta property="og:title" content="PForecasting - Susanna" />
      </Helmet>
      <Header theme="purchase"></Header>
      <PFMainContainer></PFMainContainer>
      <PFHero></PFHero>
    </div>
  )
}

export default PForecasting
