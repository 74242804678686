import React, { useState, useEffect } from 'react';
import PFMenuContainer from './p-menu-container';
import UserUploadStrip from './p-user-upload';
import DataViewer from './p-data-viewer';
import DownloadStrip from './p-download-strip';
import './p-main-container.css';

const PFMainContainer = (props) => {
  
  // State to hold the data fetched from the backend.
  const [forecastData, setForecastData] = useState(null);
  const [isDataCleared, setIsDataCleared] = useState(false);

  // State to hold the rules
  const [stopOrderRule, setStopOrderRule] = useState({ min: 0, max: 12, exc: 50, favor: "high"});
  const [orderTriggerRule, setOrderTriggerRule] = useState({ min: 0.5, max: 4, exc: 50, favor: "low"});

  const handleDataFetched = (data) => {
    setForecastData(data);
  };

  const handleDataCleared = () => {
      setForecastData(null);  // Clear the forecastData state
      setIsDataCleared(false);  // Reset the isDataCleared state when new data is fetched
  };

  // Function to update the rules from UserUploadStrip component
  const handleStopOrderRuleChange = (newRule) => {
    setStopOrderRule(newRule);
  };

  const handleOrderTriggerRuleChange = (newRule) => {
    setOrderTriggerRule(newRule);
  };


  // Menu items for the PFMenuContainer component.
  const menuItems = [
    { text: 'Home', link: "/", isLink: true },
    { text: 'Purchase', link: "/purchasing", isLink: true },
    { text: 'Analysis', link: "/purchasing/purch-analysis", isLink: true },
    { text: ' SAP Query' },
    { text: ' Documents' },
    { text: ' Inventory' },
  ];

  return (
    <div className="purch-forecasting-main ">
      <div className="p-main-container-side-container">
        <PFMenuContainer menuItems={menuItems} />
      </div>
      <div className="p-main-container-center-container">
        <UserUploadStrip 
          className="user-upload-strip" 
          onDataFetched={handleDataFetched} 
          onDataCleared={handleDataCleared}
          stop_order_rule={stopOrderRule} 
          order_trigger_rule={orderTriggerRule}
          onStopOrderRuleChange={handleStopOrderRuleChange}
          onOrderTriggerRuleChange={handleOrderTriggerRuleChange}
        />
        <DataViewer 
          className="results-visualization" 
          dataframe={forecastData}
          stop_order_rule={stopOrderRule}
          order_trigger_rule={orderTriggerRule}
        />
        <DownloadStrip className="download-strip" data={forecastData} />
      </div>
    </div>
  );
  

  
};

export default PFMainContainer;
