import React, { useState } from 'react'
import PAMenuContainer from './p-menu-container'
import PAInputContainer from './p-input-container'
import PAOutputContainer from './p-output-container'
import './p-main-container.css'

const PAMainContainer = (props) => {
  const [receivedText, setReceivedText] = useState('')
  const [receivedImage, setReceivedImage] = useState('')

  const handleTextReceived = (text) => {
    console.log("Received text:", text);
    setReceivedText(text)
  }

  const handleImageReceived = (imageUrl) => {
    console.log("Received image URL:", imageUrl);
    setReceivedImage(imageUrl)
  }

  const handleClear = () => {
    setReceivedText('')
    setReceivedImage('')
  }

  const menuItems = [
    { text: 'Home', link: "/", isLink: true },
    { text: 'Purchase', link: "/purchasing", isLink: true },
    { text: 'Forecasting', link: "/purchasing/purch-forecasting", isLink: true },
    { text: ' SAP Query' },
    { text: ' Documents' },
    { text: ' Inventory' },
  ];

  return (
    <div className="main-container">
      <div className="content-container">
        <PAMenuContainer menuItems={menuItems} />
        <div className="io-container">
        <div className="io-wrap">
              <div className="input-section">
                  <PAInputContainer
                      onTextReceived={handleTextReceived}
                      onImageReceived={handleImageReceived}
                  />
              </div>
              <div className="output-section">
                  <PAOutputContainer
                      rootClassName="output-root-class-name"
                      receivedText={receivedText}
                      onClear={handleClear}
                      imageURL={receivedImage}
                  />
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PAMainContainer
