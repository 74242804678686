import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/general/header'
import PurchMainContainer from '../components/purchase/purch-main-container'
import PurchHero from '../components/purchase/purch-hero'  // Import the new component
import './purchasing.css'

const Purchasing = (props) => {
  return (
    <div className="purchasing-container">
      <Helmet>
        <title>Purchasing - Susanna</title>
        <meta property="og:title" content="Purchasing - Susanna" />
      </Helmet>
      <Header theme="purchase"></Header>
      <PurchMainContainer></PurchMainContainer>
      <PurchHero /> 
    </div>
  )
}

export default Purchasing
