import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import './p-input-container.css'

import { SERVER_URL } from '../../config';

const PAInputContainer = (props) => {
  const [inputText, setInputText] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(`${SERVER_URL}/analysis/readInput`, {
        text: inputText
      })

      if (response.headers['content-type'] === 'image/png') {
        const imageResponse = await axios.post(`${SERVER_URL}/analysis/readInput`, {
          text: inputText
        }, {
          responseType: 'arraybuffer'
        })
        const blob = new Blob([imageResponse.data], {type: 'image/png'});
        const imageURL = URL.createObjectURL(blob);
        props.onImageReceived(imageURL);
      } else {
        props.onTextReceived(response.data.text);
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert(error.response.data.error);
      } else {
        console.error(error)
        props.onTextReceived('An error occurred while processing your request.');
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} className="p-input-container-input-container">
      <div className="p-input-container-container">
        <h1 className="p-input-container-heading">
          <span>Analysis</span>
          <br></br>
        </h1>
      </div>
      <div className="p-input-container-container1">
        <span>
          <span className="p-input-container-text3">
            What would you like to investigate?
          </span>
          <br></br>
          <br></br>
        </span>
        <input
          type="text"
          name="analysisInput"
          placeholder={props.textinput_placeholder}
          className="p-input-container-textinput input"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        />
        <button type="submit" className="submit-button">Submit</button>
      </div>
    </form>
  )
}

PAInputContainer.defaultProps = {
  textinput_placeholder: 'Type your inquiry here',
  onTextReceived: () => {},
  onImageReceived: () => {},
}

PAInputContainer.propTypes = {
  textinput_placeholder: PropTypes.string,
  onTextReceived: PropTypes.func,
  onImageReceived: PropTypes.func,
}

export default PAInputContainer
