import React from 'react'

import PropTypes from 'prop-types'

import './navigation-links1.css'

const NavigationLinks1 = (props) => {
  return (
    <nav className={`navigation-links1-nav ${props.rootClassName} `}>
      <span className="navigation-links1-text">{props.text}</span>
      <span className="navigation-links1-text1">{props.topContact}</span>
      <span className="navigation-links1-text2">{props.topContact1}</span>
      <span className="navigation-links1-text3">{props.topTeam}</span>
      <span className="navigation-links1-text4">{props.text4}</span>
    </nav>
  )
}

NavigationLinks1.defaultProps = {
  rootClassName: '',
  text: 'About',
  topContact1: 'Contact',
  topContact: 'Features',
  topTeam: 'Team',
  text4: 'Blog',
}

NavigationLinks1.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
  topContact1: PropTypes.string,
  topContact: PropTypes.string,
  topTeam: PropTypes.string,
  text4: PropTypes.string,
}

export default NavigationLinks1
