import React, { useState } from 'react';
import './p-download-strip.css';
import Papa from 'papaparse';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const DownloadStrip = ({ onDownload, data, className }) => {
  const [selectedExtension, setSelectedExtension] = useState('csv');
  const [decimalSeparator, setDecimalSeparator] = useState(',');

  const processCsvData = (data, decimalSeparator) => {
    if (decimalSeparator === ',') {
      return data.replace(/\./g, ',');
    }
    return data;
  };  

  const convertToExcel = (csvData) => {
    Papa.parse(csvData, {
      header: true,
      dynamicTyping: true,  // Automatically convert numeric strings to numbers
      complete: (result) => {
        const ws = XLSX.utils.json_to_sheet(result.data);
  
        // Explicitly set the worksheet to be unprotected
        ws['!protect'] = false;
  
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'data.xlsx');
      },
    });
  };
  

  const handleDownloadClick = () => {
    if (data) {
      if (selectedExtension === 'csv') {
        const processedData = processCsvData(data, decimalSeparator);
        const blob = new Blob([processedData], { type: 'text/csv' });
        FileSaver.saveAs(blob, 'data.csv');
      } else if (selectedExtension === 'xlsx') {
        convertToExcel(data);
      }
    }
  };

  const buttonClass = data && data.length > 0 ? 'p-download-strip-button' : 'p-download-strip-button p-download-strip-button-error';

  return (
    <div className={`p-download-strip-container ${className}`}>
      <select
        className="p-download-strip-select"
        value={selectedExtension}
        onChange={(e) => setSelectedExtension(e.target.value)}
      >
        <option value="csv">CSV</option>
        <option value="xlsx">Excel (xlsx)</option>
      </select>
      <select
        className="p-download-strip-select"
        value={decimalSeparator}
        onChange={(e) => setDecimalSeparator(e.target.value)}
      >
        <option value=",">Comma (,)</option>
        <option value=".">Dot (.)</option>
      </select>
      <button className={buttonClass} onClick={handleDownloadClick}>
        Download
      </button>
    </div>
  );
};

export default DownloadStrip;
