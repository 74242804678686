import './p-user-upload.css';
import axios from 'axios'
import { SERVER_URL } from '../../config';
import React, { useState, useRef } from 'react';
import SettingsModal from './setting-modal';


const UserUploadStrip = (props) => {
 

  // State for settings input
  const [tempStopOrderRule, setTempStopOrderRule] = useState(props.stop_order_rule); // assuming stop_order_rule is passed as a prop
  const [tempOrderTriggerRule, setTempOrderTriggerRule] = useState(props.order_trigger_rule); // assuming order_trigger_rule is passed as a prop

   // State to store input text (if needed for future modifications).
  const [inputText, setInputText] = useState('');

  const [showDropdown, setShowDropdown] = useState(false);

  // State to keep track of the upload status and uploaded file name
  const [uploadStatus, setUploadStatus] = useState({ status: 'idle', filename: '' });

  // State to control the visibility of the settings modal.
  const [showSettings, setShowSettings] = useState(false);

  // Keep track of whether there is a file already submitted in the system
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Additional state to control the transition
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Loading animation while submitting
  const [isLoading, setIsLoading] = useState(false);

  // State to track if "All" is selected
  const [selectAll, setSelectAll] = useState(false);

  const fileInputRef = useRef(null);

  // Event handler for any input change.
  const handleInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setInputText(file);
      if (allowedFile(file.name)) {
        setUploadStatus(prevState => ({ ...prevState, status: 'success', filename: file.name }));
      } else {
        setUploadStatus(prevState => ({ ...prevState, status: 'error', filename: '' }));
      }
    } else {
      setUploadStatus(prevState => ({ ...prevState, status: 'idle', filename: '' }));
    }
  };  

  // New function to handle "All" button click
  const handleSelectAll = () => {
    setSelectAll(true);
    setUploadStatus({ status: 'all', filename: 'All' }); // Set the status to 'all' and filename to 'All'
  };

  // Helper function to check if the file extension is allowed.
  const allowedFile = (filename) => {
    return filename.toLowerCase().match(/\.(csv|xlsx|txt|xls)$/);
  };

  const handleRuleChange = (setRuleFunction, field) => (event) => {
    setRuleFunction(prevRule => ({
      ...prevRule,
      [field]: event.target.value // dynamically set the field
    }));
  };

  
  // Event handler for submitting the form (or button click).
const handleSubmit = async () => {
  setIsLoading(true); // Start loading
  try {
    console.log('handleSubmit was called, updating online data');

    // refresh data on server
    await axios.post(`${SERVER_URL}/dataProc/update_data`);

    let response;
    // If "All" is not selected, proceed with file upload
    if (!selectAll) {
      const formData = new FormData();
      formData.append('file', inputText);  // ensure this file gets saved as 'objDf.csv' on the server
    
      // upload new query
      response = await axios.post(`${SERVER_URL}/upload/uploadObj`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data);
      console.log(response.data.success);
    }
    
    const ByPassSelection = selectAll;

    // Make a POST request to /process_data route whether "All" is selected or file upload succeeded
    if (selectAll || (response && response.data.success)) {
      const processDataResponse = await axios.post(
        `${SERVER_URL}/dataProc/process_data`,
        { ByPassSelection }, // Send ByPassSelection in the request body
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (processDataResponse.status === 200) {
        // The request was successful, processDataResponse.data contains the CSV string
        const csvStr = processDataResponse;
        console.log(csvStr.data);
        props.onDataFetched(csvStr.data);

        setUploadStatus({ status: 'success', filename: inputText ? inputText.name : 'All' });
        setIsSubmitted(true);
        setIsTransitioning(true);
        setTimeout(() => {
          setIsTransitioning(false);
          setUploadStatus({ status: 'idle', filename: '' });
          console.log("Process completed successfully");
        }, 2000);
      } 
    } else {
      setUploadStatus({ status: 'error', filename: '' });
      setIsTransitioning(true);
      setTimeout(() => {
        setIsTransitioning(false);
        setUploadStatus({ status: 'idle', filename: '' });
        console.log("Process failed");
      }, 2000);
    }
  } catch (error) {
    console.error("Error during the process: ", error);
    setUploadStatus({ status: 'error', filename: '' });
    setIsTransitioning(true);
    setTimeout(() => {
      setIsTransitioning(false);
      setUploadStatus({ status: 'idle', filename: '' });
    }, 2000);
  } finally {
    setIsLoading(false); // End loading irrespective of success or error
  }
};

  // Handle what happens if the clear button is hit
  const handleClear = () => {
    console.log("Clearing state")
    // Reset all state variables to their initial values
    setInputText('');
    setUploadStatus({ status: 'idle', filename: '' });
    setShowSettings(false);
    setIsTransitioning(false);
    setIsSubmitted(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setSelectAll(false); // Reset selectAll to false

    props.onDataCleared();  // Notify the parent component that the data has been cleared
  };


  // Toggle the settings modal visibility.
  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  // Apply settings and close the settings modal.
  const applyAndCloseSettings = () => {
    // Call the handlers from the parent component to update the rules
    props.onStopOrderRuleChange(tempStopOrderRule);
    props.onOrderTriggerRuleChange(tempOrderTriggerRule);

    // Close the settings modal.
    toggleSettings();
  };

  return (
    <div className={`p-user-upload-container ${props.className}`}>
      <h1 className="p-user-upload-heading">Forecasting</h1>
      <div className="p-user-upload-input-group">
        <input type="file" ref={fileInputRef} id="upload-file" style={{ display: 'none' }} onChange={handleInputChange} accept=".csv, .xlsx, .txt, .xls" />
        {/* Label acts as the button for file input */}
        <div className="p-user-upload-dropdown-container"
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}>
            <label className="p-user-upload-button" htmlFor="upload-file">Upload</label>
            {showDropdown && (
              <button 
                className="p-user-upload-button p-user-upload-dropdown-item" 
                onClick={handleSelectAll} // Set onClick to new handleSelectAll function
              >
                All
              </button>
          )}
        </div>

        {/* Button to show settings modal */}
        <button className="p-user-upload-button" onClick={toggleSettings}>Settings</button> 
        {/* Button to submit the data/file */}
        {
          isLoading ? (
            <div className="spinner-container">
                <div className="spinner"></div>
            </div>
          ) : (
            <button 
              className={`p-user-upload-button p-user-upload-submit-button ${isSubmitted ? 'p-user-upload-clear-button' : ''}`} 
              onClick={isSubmitted ? handleClear : handleSubmit}
            >
              {isSubmitted ? 'Clear' : 'Submit'}
            </button>
          )
        }

        <div className={`status-container ${uploadStatus.status} ${isTransitioning ? 'transitioning' : ''} ${selectAll ? 'all-selected' : ''}`}>
          {uploadStatus.status === 'success' || uploadStatus.status === 'all' ? uploadStatus.filename : ''}
        </div>

      </div>

      <div>
      {/* ... your other components ... */}
      <SettingsModal 
        showSettings={showSettings}
        toggleSettings={toggleSettings}
        handleRuleChange={handleRuleChange}
        tempStopOrderRule={tempStopOrderRule}
        setTempStopOrderRule={setTempStopOrderRule}
        tempOrderTriggerRule={tempOrderTriggerRule}
        setTempOrderTriggerRule={setTempOrderTriggerRule}
        applyAndCloseSettings={applyAndCloseSettings}
      />
    </div>

    </div>
  );
};

export default UserUploadStrip;
