import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/general/header'
import PAMainContainer from '../components/purchaseAnalysis/p-main-container'
import PAHero from '../components/purchaseAnalysis/p-hero'
import './purch-analysis.css'

const PAnalysis = (props) => {
  return (
    <div className="purch-analysis-container">
      <Helmet>
        <title>PAnalysis - Susanna</title>
        <meta property="og:title" content="PAnalysis - Susanna" />
      </Helmet>
      <Header theme="purchase"></Header>
      <PAMainContainer></PAMainContainer>
      <PAHero></PAHero>
    </div>
  )
}
export default PAnalysis
