// src/components/HOCs/ProtectedRoute.js

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, accessLevel, requiredLevel, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      accessLevel >= requiredLevel ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/not-authorized',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default ProtectedRoute;
