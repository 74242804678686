import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import './purch-main-container.css'
import SidebarMenu from './purch-menu-container'

const PurchMainContainer = () => {
  const menuItems = [
    { text: 'Home', link: "/", isLink: true },
    { text: 'SAP Query' },
    { text: 'Documents' },
    { text: 'Analysis', link: "/purchasing/purch-analysis", isLink: true },
    { text: 'Forecasting', link: "/purchasing/purch-forecasting", isLink: true },
    { text: 'Inventory' },
  ];

  return (
    <div className="purch-main-container-puch-main-container">
      <div className="purch-main-container-purch-center-container">
        <div className="purch-main-container-side-container">
          <SidebarMenu menuItems={menuItems} />
        </div>
        <div className="purch-main-container-central-panel">
          <div className="purch-main-container-container"></div>
          <h1>
            <span className="purch-main-container-text08">Susanna AI</span>
            <br></br>
            <span>Purchasing</span>
          </h1>   
        </div>
          <div className="purch-main-container-image-container"></div>
      </div>
    </div>
  )
}

export default PurchMainContainer
