import React from 'react'

const PurchHero = () => (
  <div className="purchasing-p-hero">
    <div className="purchasing-container1">
      <h1 className="purchasing-text">How to use Purchasing</h1>
      <span className="purchasing-text1">
        <br></br>
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non
          volutpat turpis.
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <span>
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <span>
          Mauris luctus rutrum mi ut rhoncus. Integer in dignissim tortor.
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <span>
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
      </span>
      <button className="purchasing-button button">Get Started</button>
    </div>
    <img
      alt="image"
      src="https://images.unsplash.com/photo-1471086569966-db3eebc25a59?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIwfHxtaW5pbWFsaXNtJTIwcGxhbnR8ZW58MHx8fHwxNjI2MTgyODMw&amp;ixlib=rb-1.2.1&amp;w=1200"
      className="purchasing-image"
    />
  </div>
);

export default PurchHero;
